import { lazy } from "react";

import { PageAtRouteMap } from "@/pages/pages.types";

export * from "@/pages/pages.types";

/**
 * Define Pages as a lazy loaded components from their folders
 */
export const DashboardPage = lazy(() => import("@/pages/DashboardPage"));
export const HorizonVersionPage = lazy(() => import("@/pages/HorizonVersionPage"));
export const NumberOfRobotsPage = lazy(() => import("@/pages/NumberOfRobotsPage"));
export const CustomerDetailPage = lazy(() => import("@/pages/CustomerDetailPage"));
export const HorizonServicesPage = lazy(() => import("@/pages/HorizonServicesPage"));
export const HorizonDatabaseHealthPage = lazy(() => import("@/pages/HorizonDatabaseHealthPage"));

/**
 * Create an object where routes are associated the their page components
 */
export const pageAtRouteMap = {
    dashboard: DashboardPage,
    horizonVersion: HorizonVersionPage,
    numberOfRobots: NumberOfRobotsPage,
    customerDetail: CustomerDetailPage,
    horizonServices: HorizonServicesPage,
    horizonDatabaseHealth: HorizonDatabaseHealthPage,
} satisfies Partial<PageAtRouteMap>;
