import z from "zod";
import { severityType } from "./severity-type.enum";
const faultyServiceSchema = z.object({
    serviceTitle: z.string(),
    serviceSeverity: severityType,
});
export const nonfunctionalServicesCustomerOverviewDtoSchema = z.object({
    customerName: z.string(),
    customerNumber: z.string(),
    farmLicenseKey: z.string(),
    customerCountry: z.string().nullable(),
    faultyServices: z.array(faultyServiceSchema),
    clusterName: z.string(),
    lelyCenterName: z.string().nullable(),
});
