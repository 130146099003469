import { Button } from "@datamole/wds-component-button";
import { Input } from "@datamole/wds-component-input";
import { Navbar as WdsNavbar } from "@datamole/wds-component-navbar";
import { faCog, faBell, faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import styles from "@/components/Layout/Navbar/Navbar.module.css";
import { UserMenu } from "@/components/Layout/Navbar/actions/UserMenu";

type Props = {
    className: string;
};

const Navbar = ({ className }: Props) => {
    return (
        <WdsNavbar className={clsx(className, styles.navbar)}>
            <WdsNavbar.Content>
                <Input name="search" placeholder="Find a customer..." className={styles.search_bar} disabled />
            </WdsNavbar.Content>
            <WdsNavbar.Actions>
                <Button className={styles.action_button} disabled>
                    <FontAwesomeIcon icon={faBell} />
                </Button>

                <WdsNavbar.Divider />

                <Button className={styles.action_button} disabled>
                    <FontAwesomeIcon icon={faCog} />
                </Button>

                <Button className={styles.action_button} disabled>
                    <Button.Icon>
                        <FontAwesomeIcon icon={faExternalLink} />
                    </Button.Icon>
                    <Button.Content>LSSA</Button.Content>
                </Button>

                <UserMenu />
            </WdsNavbar.Actions>
        </WdsNavbar>
    );
};

export { Navbar as Navbar };
